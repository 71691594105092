@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@layer base {
  html {
    scroll-snap-type: y mandatory;
  }
  body {
    @apply font-[Poppins];
    overflow: scroll;
    scroll-snap-type: y mandatory;
  }
  .snap-center {
    scroll-snap-align: center;
  }
  li {
    @apply px-4;
    @apply cursor-pointer;
  }

  @media (min-width: 768px) {
    /* turn off snap scroll for md+ devices */
    html {
      scroll-snap-type: none;
    }
    body {
      overflow: auto;
      scroll-snap-type: none;
    }
  }
}

.active {
  /* sets active section color for navbar links */
  color: #9bc53d;
}

/* start work cards */
.content-div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-position: top left;
  height: 30vh;
}

.section {
  /* height: full; */
  padding-top: 80px;
}
@media (min-width: 640px) {
  .section {
    /* height: full; */
    padding-top: 100px;
  }
}
@media (min-width: 768px) {
  .section {
    /* height: full; */
    padding-top: 150px;
  }
}
@media (min-width: 1536px) {
  .section {
    /* height: full; */
    padding-top: 80px;
  }
}

/* responsive work card heights */
@media (min-width: 768px) {
  .content-div {
    height: 25vh;
  }
}
@media (min-width: 1280px) {
  .content-div {
    height: 50vh;
  }
}
/* end work cards */

@media (hover: hover) {
  /* targets devices that can hover */
  .touchable:hover {
    margin-left: -10px;
  }
  .touchable:focus-within {
    margin-left: -10px;
  }
}

@media (hover: none) {
  /* targets devices that don't hover (touch) */
  .touchable {
    margin-left: -100px;
  }
  .touchable.open {
    margin-left: -10px;
  }
}

/* hover for work cards */
.content-div:hover {
  background-image: linear-gradient(to right, #9bc53d80, #9bc53d) !important;
}

/* start floating label for contact form */
input:focus ~ label,
input:not(:placeholder-shown) ~ label,
textarea:focus ~ label,
textarea:not(:placeholder-shown) ~ label,
select:focus ~ label,
select:not([value=""]):valid ~ label {
  /* @apply transform; scale-75; -translate-y-6; */
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-scale-x: 0.75;
  --tw-scale-y: 0.75;
  --tw-translate-y: -1.5rem;
}

input:focus ~ label,
select:focus ~ label {
  /* @apply text-black; left-0; */
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  left: 0px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}
/* end floating label */
